import * as React from 'react';
import styled from 'styled-components';
import { Container, ContainerPage, tablet, mobile } from '../../components/container';
import { allGames } from '../../games/Games';
import { GameSummary } from '../../components/game';
import * as fonts from '../../styling/fonts';
import { Helmet } from 'react-helmet';
import { Breadcrumbs } from '../../components/breadcrumbs';
import Layout from '../../components/layouts';

const CardDrinkingGames = () => (
    <Layout>
        <Helmet>
            <title>Card Drinking Games | pre-drinks.com</title>
            <meta
                name="description"
                content="All the best drinking games you can play with a pack of cards. Ideal for pre-drinks sessions, student house parties, and University Freshers week."
            />
        </Helmet>
        <Breadcrumbs crumbs={[{ url: 'DrinkingGames', name: 'Drinking Games' }, { url: 'DrinkingGames/CardDrinkingGames', name: 'Card Drinking Games' }]} />
        <Wrapper>
            <ContainerPage>
                <Container>
                    <h1>Card Drinking Games</h1>
                    {allGames.filter(g => g.category === 'Card').map(g => <GameSummary gameDetails={g} />)}
                </Container>
            </ContainerPage>
        </Wrapper>
    </Layout>
);

const Wrapper = styled.div`
    padding-top: 40px;
    padding-bottom: 30px;

    @media (max-width: ${tablet}px) {
      padding-top: 25px;
    }

    @media (max-width: ${mobile}px) {
      padding-top: 15px;
    }

    h1 {
        font-size: 2em;
        font-family: ${fonts.headerFont};
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 25px;
        opacity: 0.75;
    }
`;

export default CardDrinkingGames;
